import React from 'react';
import { Helmet } from 'react-helmet';
import urljoin from 'url-join';
import config from '../../../data/SiteConfig';

const SEO = (props) => {
  const { meta, blog } = props;

  const { title } = meta;
  const description = meta.desc;
  const url = urljoin(config.siteUrl, config.pathPrefix, meta.slug);
  const logo = urljoin(config.siteUrl, config.pathPrefix, config.siteLogo);
  let image = meta.image || config.sitePreviewImage;

  if (
    !image.match(
      `(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]`,
    )
  ) {
    image = urljoin(config.siteUrl, config.pathPrefix, image);
  }

  const schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: config.appName,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
      address: {
        '@type': 'PostalAddress',
        streetAddress:
          '1st Floor, Capital FM Building, Thapagaun, New Baneshwor',
        addressLocality: 'Kathmandu',
        addressRegion: 'Bagmati',
        postalCode: '44600',
        addressCountry: 'NP',
      },
      image,
      logo,
      email: 'support@danfebooks.com',
      telephone: '+977-01-5245075',
      url: config.siteUrl,
      paymentAccepted: ['cheque', 'credit card', 'invoice'],
      openingHours: 'Mo,Tu,We,Th,Fr 09:00-05:00',
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          opens: '09:00',
          closes: '05:00',
        },
      ],
      geo: {
        '@type': 'GeoCoordinates',
        latitude: '27.691191',
        longitude: '85.332528',
      },
      priceRange: '$$',
    },
  ];

  if (blog) {
    schemaOrgJSONLD.push(
      {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        name: `${config.appName} - Breadcrumbs`,
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: config.appName,
            item: config.siteUrl,
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: 'Blog',
            item: `${config.siteUrl}/blog`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: title,
            item: url,
          },
        ],
      },
      {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        url,
        name: title,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        description,
        author: {
          '@type': 'Person',
          name: 'Shekhar K. Sharma',
        },
        datePublished: meta.datePublished || '',
        dateModified: meta.dateModified || meta.datePublished || '',
        publisher: {
          '@type': 'Organization',
          name: config.appName,
          logo: {
            '@type': 'ImageObject',
            url: logo,
          },
        },
        mainEntityOfPage: 'True',
      },
    );
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <link rel="canonical" href={url} />

      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      <meta property="og:site_name" content={config.appName} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={blog ? 'article' : 'website'} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.userTwitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta property="twitter:url" content={url} />
    </Helmet>
  );
};

export default SEO;
